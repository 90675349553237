@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Ubuntu Sans", sans-serif;
}

.bree-serif-regular {
  font-family: "Bree Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #374151;
}

.first-line {
  width: 3rem;
  height: 0.1rem;
  background-color: #374151;
  margin: 0 0 0.8rem 2rem;
}

.second-line {
  width: 3rem;
  height: 0.1rem;
  background-color: #374151;
  margin: 0 0 0.8rem 2rem;
}

.third-line {
  width: 3rem;
  height: 0.1rem;
  background-color: #374151;
  margin: 0 0 0 2rem;
}

.rotate {
  transform: rotateY(70deg);
}

.hidden {
  display: none;
}

/* Custom CSS for Confetti Effect */
.confetti-piece {
  opacity: 0;
  animation: fall 3s linear infinite;
}

.left-confetti {
  animation: left-fall 3s linear infinite;
}

.right-confetti {
  animation: right-fall 3s linear infinite;
}

@keyframes left-fall {
  0% {
    left: -10vw;
    opacity: 1;
    transform: rotate(0deg);
  }
  50% {
    left: 50vw;
    opacity: 1;
  }
  100% {
    left: 80vw;
    top: 110vh;
    opacity: 0;
    transform: rotate(360deg);
  }
}

@keyframes right-fall {
  0% {
    left: 100vw;
    opacity: 1;
    transform: rotate(0deg);
  }
  50% {
    left: 50vw;
    opacity: 1;
  }
  100% {
    left: 80vw;
    top: 110vh;
    opacity: 0;
    transform: rotate(360deg);
  }
}

.no-scroll {
  overflow: hidden;
}
